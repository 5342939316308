/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';

import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { Modal } from '../../../../components/other/Modals';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';

import { setLayoutSubheader } from '../../../../utils/layout';
import { BOT_ROLE } from '../constants';
import homeStyles from '../../../../constants/homeStyles';
import { useStyles } from './hooks/useStyles';
import { useFormatMessage } from '../../../../hooks';
import { useGetUsers } from './hooks/useGetUsers';
import { useCreateBot } from './hooks/useCreateBot';
import { ButtonWithLoader } from '../../../../components/other/Buttons';
import { useDeleteBot } from './hooks/useDeleteBot';

const Bots: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const homeClasses = homeStyles();
  const history = useHistory();
  const fm = useFormatMessage();

  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState<number>();

  const { fetchUsers, page, perPage, users, loading, total } = useGetUsers();
  const { fetchCreate, loading: loadingCreate, success: successCreate } = useCreateBot();
  const { fetchDelete, loading: loadingDelete, success: successDelete } = useDeleteBot();

  useEffect(() => {
    fetchUsers(BOT_ROLE, page, perPage);
  }, [successCreate, successDelete]);

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'SUBMENU.BOTS' }),
  });

  return (
    <>
      <Row>
        <Col>
          <ButtonWithLoader onPress={() => fetchCreate()} loading={loadingCreate}>
            {fm('BOT.ADD.BUTTON')}
          </ButtonWithLoader>

          <div className={classes.container}>
            {loading ? (
              <div className={classes.preloaderContainer}>
                <Preloader />
              </div>
            ) : !users.length ? (
              <Typography className={classes.empty} component='h5' variant='h5'>
                {fm('COMMON.LIST.EMPTY')}
              </Typography>
            ) : (
              <Card className={classes.tableContainer}>
                <CardContent className={homeClasses.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>{fm('USER.TABLE.ID')}</SHeadTableCell>
                        <SHeadTableCell>{fm('TOKEN')}</SHeadTableCell>
                        <SHeadTableCell align='right'>
                          {fm('USER.TABLE.ACTION')}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            {item.api_token || '-'}
                          </TableCell>

                          <TableCell align='right'>
                            <div className={classes.actions}>
                              <IconButton
                                color='secondary'
                                aria-label='previous'
                                onClick={() => {
                                  setUserId(item.id);
                                  setOpen(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          page={page}
                          realPerPage={users.length}
                          perPage={perPage}
                          total={total}
                          fetchRows={(data: any) =>
                            fetchUsers(BOT_ROLE, data.page, data.perPage)
                          }
                          label={fm('BOTS.PERPAGE')}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </Card>
            )}
          </div>
        </Col>
      </Row>

      <Modal
        open={open}
        onClose={loadingDelete ? () => {} : () => setOpen(false)}
        title={fm('BOT.REMOVE.ALERT.TITLE')}
        loading={loadingDelete}
        content={loadingDelete ? undefined : fm('BOT.REMOVE.ALERT')}
        actions={
          loadingDelete
            ? undefined
            : [
                {
                  title: fm('COMMON.BUTTON.CANCEL'),
                  onClick: () => setOpen(false),
                },
                {
                  title: fm('COMMON.BUTTON.DELETE'),
                  onClick: () => {
                    userId && fetchDelete(userId);
                    setOpen(false);
                  },
                },
              ]
        }
      />
    </>
  );
};

export default Bots;
