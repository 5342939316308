import React from 'react';
import { TablePagination } from '@material-ui/core';
import { useIntl } from 'react-intl';
import TablePaginationActions from './TablePaginationActions';

export interface IProps {
  id?: number;
  label?: string;
  page: number;
  realPerPage?: number;
  perPage: number;
  total: number;
  fetchRows?: any;
  statuses?: string;
  companyId?: string;
  parentId?: number | string;
}

const TablePaginator: React.FC<IProps> = ({
  id,
  label,
  page,
  realPerPage,
  perPage,
  total,
  fetchRows,
  statuses,
  companyId,
  parentId,
}) => {
  const intl = useIntl();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (fetchRows) {
      if (parentId) {
        fetchRows({ page: newPage + 1, perPage, parentId });
      } else if (id) {
        fetchRows({ page: newPage + 1, perPage, id });
      } else if (statuses) {
        fetchRows({ page: newPage + 1, perPage, statuses });
      } else if (companyId) {
        fetchRows({ page: newPage + 1, perPage, companyId });
      } else {
        fetchRows({ page: newPage + 1, perPage });
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (fetchRows) {
      if (parentId) {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), parentId });
      } else if (id) {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), id });
      } else if (statuses) {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), statuses });
      } else if (companyId) {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10), companyId });
      } else {
        fetchRows({ page: 1, perPage: parseInt(event.target.value, 10) });
      }
    }
  };

  return (
    <TablePagination
      rowsPerPageOptions={[10, 20, 50]}
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      labelRowsPerPage={label}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}${realPerPage === 1 ? '' : '-'}${
          realPerPage === 1 ? '' : to
        } ${intl.formatMessage({
          id: 'TABLE.FROM.LABEL',
        })} ${count}`
      }
    />
  );
};

export default TablePaginator;
